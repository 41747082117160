<template>
  <b-modal
    :id="`modal-importar-${modulo}`"
    v-model="modalShow"
    centered
    size="lg"
    :title="`Importar ${modulo}`"
    width="900px"
    hide-footer
  >
    <b-button
      class="mt-1"
      variant="outline-success"
      block
      size="sm"
      :title="`Formato para importar ${modulo}`"
      :disabled="cargando"
      @click.prevent="!cargando
        ? downloadFormat()
        : ''"
    >
      Descargar Formato
    </b-button>
    <spinner
      v-if="cargando"
      class="mt-3"
    />
    <div
      v-else
      class=" flex-wrap"
    >
      <!-- {{ file.name }} -->
      <b-form-file
        id="input-file-import"
        ref="importFile"
        v-model="file"
        name="file_import"
        class="mt-1"
        type="file"
        accept=".xls, .xlsx, .csv"
        :placeholder="!fileName
          ? `Selecciona el archivo para importar ${modulo}...`
          : fileName"
        drop-placeholder="Arrastra tu archivo aquí..."
        style="cursor: pointer;"
        @change="onFileChange"
      />
      <div
        v-if="dataExcel.length"
        class="mt-1 text-success"
      >
        <feather-icon
          icon="CheckIcon"
        />
        {{ countCargados }} {{ modulo }} cargados correctamente
      </div>

      <div
        v-if="headerErrorMsg.length"
        class="mt-3 mb-2 text-danger"
      >
        <feather-icon
          icon="XIcon"
        />
        ERROR DE ENCABEZADO: <br><br>
        <div v-for="(error, i) in headerErrorMsg" v-bind:key="i">
          - {{ error }}
        </div>
      </div>
      <template
        v-if="dataError.length"
      >
        <div
          class="text-danger"
        >
          <feather-icon
            icon="XIcon"
          />
          {{ countErrors }} {{ modulo }} no cargados
        </div>
        <errorLog
          v-if="dataExcel.length > 1"
          :modulo="modulo"
          @exportarErrores="exportarErrores"
          @exportarLog="exportarLog"
        />
      </template>
      <div
        class="d-flex align-items-center justify-content-end"
      >
        <b-button
          class="mt-1 mb-1 mr-1 btn-sm-block"
          variant="outline-secondary"
          :disabled="cargando"
          @click.prevent="closeModal()"
        >
          Cerrar
        </b-button>
        <b-button
          class="mt-1 mb-1 btn-sm-block"
          variant="primary"
          :disabled="!disabledImportBtn"
          @click.prevent="disabledImportBtn
            ? processImport()
            : ''"
        >
          Importar {{ modulo }}
          {{
            dataError.length
              ? ' Cargados Correctamente'
              : ''
          }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal, BFormFile, BButton,
} from 'bootstrap-vue'
import readXlsxFile from 'read-excel-file'
import { validationMixin } from 'vuelidate'
import {
  required, maxLength, email,
} from 'vuelidate/lib/validators'
import axios from 'axios'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import XLSX from 'xlsx'

const spinner = () => import('@/layouts/components/Recycled/Form/spinner.vue')
const errorLog = () => import('@/layouts/components/Proveedores/components/errorLogs.vue')

export default {
  components: {
    BModal,
    BFormFile,
    BButton,

    spinner,
    errorLog,
  },
  mixins: [validationMixin],
  props: {
    modulo: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dataExcel: [],
      dataError: [],
      dataErrorMsg: [],
      headerErrorMsg: '',
      fileName: '',
      file: null,
      headerValido: false,
      modalShow: false,
      // disabledImportBtn: false,
      cargando: false,
      descargados: [],
      proveedor: {
        nombre: '',
        rut: '',
        razonSocial: '',
        direccion: '',
        telefono: '',
        correo: '',
        giro: '',
      },
      nombreColumna: [
        'Nombre',
        'Rut',
        'Razón Social',
        'Dirección',
        'Teléfono',
        'Correo',
        'Giro',
      ],
      columna: {
        0: 'A',
        1: 'B',
        2: 'C',
        3: 'D',
        4: 'E',
        5: 'F',
        6: 'G',
      },
    }
  },
  computed: {
    countCargados() {
      const result = this.dataExcel.length > 0
        ? Number(this.dataExcel.length - 1)
        : false
      return result
    },
    countErrors() {
      return this.dataError.length
    },
    disabledImportBtn() {
      let disabled
      if (this.dataExcel.length > 1) {
        if (this.dataError.length > 0) {
          if (this.descargados.length === 2) {
            disabled = true
          } else {
            disabled = false
          }
        } else {
          disabled = true
        }
      }
      return disabled
    },
  },
  watch: {
    modalShow() {
      if (!this.modalShow) {
        this.resetModal()
      }
    },
  },
  validations() {
    return {
      proveedor: {
        rut: {
          required,
          maxLength: maxLength(50),
        },
        direccion: {
          required,
        },
        razonSocial: {
          required,
          maxLength: maxLength(200),
        },
        nombre: {
          required,
          maxLength: maxLength(80),
        },
        telefono: {
          required,
          maxLength: maxLength(25),
        },
        correo: {
          required,
          maxLength: maxLength(80),
          email,
        },
        giro: {
          required,
          maxLength: maxLength(80),
        },
        estado: {},
      },
    }
  },
  methods: {
    ...mapActions({
      fetchProveedores: 'proveedores/fetchProveedores',
      importProveedores: 'proveedores/importProveedores',
    }),
    resetModal() {
      this.dataExcel = []
      this.dataError = []
      this.dataErrorMsg = []
      this.headerErrorMsg = []
      this.descargados = []
      this.file = null
      this.fileName = ''
    },
    downloadFormat() {
      const urlModulo = this.modulo.toLowerCase()
      const url = `${axios.defaults.baseURL}/${urlModulo}/exportFormato`
      window.location.href = url
    },
    onFileChange(event) {
      this.resetModal()
      this.cargando = true
      const xlsxfile = event.target.files ? event.target.files[0] : null
      this.fileName = xlsxfile.name
      readXlsxFile(xlsxfile).then(data => {
        this.validarImport(data)
        this.cargando = false
      })
    },
    validarImport(data) {
      data.forEach((filaData, keyDataFila) => {
        if (keyDataFila === 0) {
          const response = this.validaHeaderExcel(filaData)
          if (response) {
            this.headerValido = false
            this.headerErrorMsg = response
          } else {
            this.headerValido = true
            this.dataExcel.push(filaData)
          }
        } else if (this.headerValido) {
          this.validaBodyExcel(filaData)
          if (!this.$v.proveedor.$invalid) {
            this.dataExcel.push(filaData)
          } else {
            this.dataError.push(filaData)
          }
        }
      })
    },
    validaHeaderExcel(header) {
      let alert = false
      let alertHeader
      let alertCelda
      let errorCantidadCol
      let errorFaltaEncabezado
      const errorMsg = []
      if (this.nombreColumna.length !== header.length) {
        errorCantidadCol = `CANTIDAD DE COLUMNAS: Se han ingresado
          ${header.length} columnas, de ${this.nombreColumna.length}
          solicitadas en el formato.`
        errorMsg.push(errorCantidadCol)
        alert = true
      }

      this.nombreColumna.forEach((col, key) => {
        if (header[key] !== col) {
          alertHeader = col
          alertCelda = `${this.columna[key]}1`
          alert = true
        }
      })

      if (alertHeader && alertCelda) {
        errorFaltaEncabezado = `FALTA TÍTULO DE ENCABEZADO: El título
        "${alertHeader}", no se encuentra en la celda "${alertCelda}" del
        documento seleccionado. Corrija el documento y vuelva a intentarlo.`
        errorMsg.push(errorFaltaEncabezado)
      }

      return alert ? errorMsg : ''
    },
    validaBodyExcel(filaData) {
      const numFilaError = this.dataError.length + 2
      filaData.forEach((colData, keyColData) => {
        const celda = `Celda ${this.columna[keyColData]}${numFilaError}`
        const nombreCol = `${this.nombreColumna[keyColData]}`
        const msgRutaLog = `${celda}: ${nombreCol} ->`
        const msgRequerido = 'es requerido'
        let msg = ''
        switch (keyColData) {
          case 0:
            this.proveedor.nombre = colData
            if (!this.$v.proveedor.nombre.required) {
              msg = `${msgRutaLog} ${msgRequerido}`
            }
            if (!this.$v.proveedor.nombre.maxLength) {
              msg = `${msgRutaLog} debe tener máximo 80 caracteres`
            }
            break
          case 1:
            this.proveedor.rut = colData
            if (!this.$v.proveedor.rut.required) {
              msg = `${msgRutaLog} ${msgRequerido}`
            }
            if (!this.$v.proveedor.rut.maxLength) {
              msg = `${msgRutaLog} debe tener máximo 50 caracteres`
            }
            break
          case 2:
            this.proveedor.razonSocial = colData
            if (!this.$v.proveedor.razonSocial.required) {
              msg = `${msgRutaLog} ${msgRequerido}`
            }
            if (!this.$v.proveedor.razonSocial.maxLength) {
              msg = `${msgRutaLog} debe tener máximo 20 caracteres`
            }
            break
          case 3:
            this.proveedor.direccion = colData
            if (!this.$v.proveedor.direccion.required) {
              msg = `${msgRutaLog} ${msgRequerido}`
            }
            break
          case 4:
            this.proveedor.telefono = colData
            if (!this.$v.proveedor.telefono.required) {
              msg = `${msgRutaLog} ${msgRequerido}`
            }
            if (!this.$v.proveedor.telefono.maxLength) {
              msg = `${msgRutaLog} debe tener máximo 25 caracteres`
            }
            break
          case 5:
            this.proveedor.correo = colData
            if (!this.$v.proveedor.correo.required) {
              msg = `${msgRutaLog} ${msgRequerido}`
            }
            if (!this.$v.proveedor.correo.maxLength) {
              msg = `${msgRutaLog} debe tener máximo 25 caracteres`
            }
            if (!this.$v.proveedor.correo.email) {
              msg = `${msgRutaLog} debe ser un correo valido`
            }
            break
          case 6:
            this.proveedor.giro = colData
            if (!this.$v.proveedor.giro.required) {
              msg = `${msgRutaLog} ${msgRequerido}`
            }
            if (!this.$v.proveedor.giro.maxLength) {
              msg = `${msgRutaLog} debe tener máximo 80 caracteres`
            }
            break

          default:
            break
        }
        this.$v.proveedor.$touch()
        if (msg.length) {
          this.dataErrorMsg.push(msg)
        }
      })
    },

    exportarErrores() {
      const findDescargado = this.descargados.filter(doc => doc !== 'exportarErrores')
      this.descargados = findDescargado
      if (this.dataError) {
        this.dataError.unshift(this.nombreColumna)
        const data = XLSX.utils.json_to_sheet(this.dataError, { skipHeader: true })
        const workbook = XLSX.utils.book_new()
        const filename = `errorData${this.modulo}`
        XLSX.utils.book_append_sheet(workbook, data, filename)
        XLSX.writeFile(workbook, `${filename}.xlsx`)
        this.descargados.push('exportarErrores')

        // const urlModulo = this.modulo.toLowerCase()
        // const url = `${axios.defaults.baseURL}/${urlModulo}/errors/import/${this.dataError}`
        // window.location.href = url
        // this.descargados.push('exportarErrores')
      }
    },
    exportarLog() {
      const findDescargado = this.descargados.filter(doc => doc !== 'exportarLog')
      this.descargados = findDescargado
      if (this.dataErrorMsg) {
        const urlModulo = this.modulo.toLowerCase()
        const urlLog = `${axios.defaults.baseURL}/${urlModulo}/errors/logs/${this.dataErrorMsg}`
        window.location.href = urlLog
        this.descargados.push('exportarLog')
      }
    },
    processImport() {
      this.cargando = true
      this.importProveedores(this.dataExcel)
        .then(() => {
          this.fetchProveedores().then(() => {
            this.resetModal()
            this.closeModal()
            this.cargando = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Proveedores creados con éxito 👍',
                icon: 'CheckIcon',
                text: 'El excel de proveedores fue importado con éxito!',
                variant: 'success',
              },
            },
            {
              timeout: 4000,
            })
          })
        })
    },
    closeModal() {
      this.resetModal()
      this.$root.$emit('bv::hide::modal', `modal-importar-${this.modulo}`)
    },
  },
}
</script>

<style>

</style>
